<template>
  <!-- Add modal here -->
  <div class="documentary-evidence">
    <AppModal
      v-if="showModal"
      :value="showModal"
      :dismissible="true"
      :content-centered="true"
      :persistent="true"
      :content-no-padding="true"
      class="documentary-evidence-modal"
    >
      <!-- Header -->
      <template #header>
        <!-- main modal -->
        <template>
          <AppModalHeader :class="{ 'xs-header': mq.current === 'xs' }">
            <h3 class="type-h3">
              {{ formattedTitle }}
            </h3>
            <template #right>
              <AppBackButton icon="x" name="Close" @click="goToPendingActions()" />
            </template>
          </AppModalHeader>
        </template>
      </template>

      <!-- Loader -->
      <div v-if="isLoading" class="documentary-evidence-loader">
        <AppSpinner :size="40" :loading="isLoading" />
      </div>

      <!-- Main content -->
      <div v-show="!isLoading">
        <!-- Instructions -->
        <div>
          <ul
            v-if="
              documentId === 'bankStatement' ||
              documentId === 'idBankStatement' ||
              documentId === 'supplierInvoice' ||
              documentId === 'idCustomerInvoice'
            "
          >
            <li>Must be dated within the last <strong>90 days.</strong></li>
          </ul>
          <ul v-else-if="documentId === 'idArticleOfOrganization'">
            <li v-if="country === 'US'">
              If <strong>LLC</strong>, provide Article of Organization
            </li>
            <li>If <strong>Corporation</strong>, provide Certificate of Incorporation</li>
            <li>
              If <strong>Sole Proprietorship</strong>, provide one of the following: Business
              License, Permit or Brand Name Registration
            </li>
          </ul>
        </div>
        <!-- Alert -->
        <AppAlert class="type-caption mt-5" theme="gray">
          <div>
            <AppIcon class="icon" color="gold" size="15">
              <IconWarningAltFilled />
            </AppIcon>
            Make sure the document to be uploaded is clear, legible and of good quality for review.
          </div>
        </AppAlert>

        <!-- Upload component -->
        <XeFileUpload
          class="py-4"
          :display-options="showOptions"
          :is-download-enabled="isFileDataExist"
          :data="stateFile"
          :accept="acceptedFileTypes"
          :max-size="20"
          :clear="clearUpload"
          :is-mobile-view="mq.current === 'sm' || mq.current === 'xs'"
          @uploaded="setFileUploaded"
          @cleared="uploadAgain"
        />

        <!-- Confirm docs -->
        <div v-show="isFileUploaded" v-if="mq.current !== 'xs'">
          <!-- Upload again -->
          <AppButton
            v-if="mq.current !== 'xs' && isFileDataExist"
            type="submit"
            class="w-full my-2"
            @click="saveFile"
          >
            Save & Continue
          </AppButton>
          <AppButton theme="text" class="w-full mt-2" @click="uploadAgain">
            Upload file again
          </AppButton>
        </div>
      </div>

      <!-- Footer -->
      <template v-if="mq.current == 'xs' && isFileDataExist" #footer>
        <!-- Confirm docs -->
        <AppModalFooter v-show="isFileUploaded">
          <AppButton theme="text" @click="uploadAgain"> Upload file again </AppButton>
          <AppButton
            type="submit"
            class="documentary-evidence-mobile-submit-button"
            @click="saveFile"
          >
            Save & Continue
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { useMediaQuery } from '@en-ui/composables/useMediaQuery'
import { computed, reactive, ref, watch } from '@vue/composition-api'
import { useRouter } from '@/composables/useRouter'
import { useStore } from '@/composables/useStore'

import AppModal from '@/components/AppModal/AppModal'
import AppAlert from '@/components/AppAlert/AppAlert.vue'
import AppModalHeader from '@/components/AppModalHeader/AppModalHeader'
import AppModalFooter from '@/components/AppModalFooter/AppModalFooter'
import XeFileUpload from '@/components/XeFileUpload/XeFileUpload'
import AppBackButton from '@en-ui/components/AppBackButton/AppBackButton'
import AppButton from '@/components/AppButton/AppButton'
import AppSpinner from '@/components/AppSpinner/AppSpinner'

import AppIcon from '@/components/AppIcon/AppIcon'
import { IconWarningAltFilled } from '@moneytransfer.ui/euronet-icons'

import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'

const JSZip = require('jszip')

export default {
  name: 'UploadModal',
  components: {
    AppAlert,
    AppModal,
    AppModalHeader,
    AppModalFooter,
    XeFileUpload,
    AppBackButton,
    AppButton,
    AppSpinner,
    AppIcon,
    IconWarningAltFilled,
  },
  props: {
    documentId: {
      type: String,
      default: null,
    },
    documentTitle: {
      type: String,
      default: null,
    },
    documentName: {
      type: String,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: true,
    },
    documentStatus: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()

    // Format style - input:bank-statement => output:Bank Statement
    const formattedTitle = props.documentName
      .replace(/[^\w\s']/gi, ' ')
      .replace(/(^\w|\s\w)/, (match) => match.toUpperCase())
    const clearUpload = ref(false)
    const fileUploadedUrl = ref('')
    const fileUploadedPdf = ref('')
    const openConfirm = ref(false)
    const acceptedFileTypes = ['application/pdf', 'image/jpg', 'image/png', 'image/jpeg']
    const getUploadedFile = computed(() => dashboardStore.uploadedFile[props.documentId])
    const stateFile = ref(getUploadedFile.value)
    const isFileDataExist = ref(false)

    // check if data is arrayBuffer with byteLength
    if (stateFile?.value?.data) {
      if (stateFile?.value?.data?.byteLength) {
        isFileDataExist.value = true
      }
    }

    const showOptions = ref(stateFile && stateFile !== undefined)
    const isFileUploaded = ref(Object.keys(stateFile.value).length > 0)
    const isLoading = ref(false)
    const corpProfile = dashboardStore.corpProfile
    const country = ref(corpProfile.registrationAddress.country)

    if (props.documentStatus === 'pending') {
      analyticsStore.track({
        event: 'Pending Action Started',
        traits: {
          ActionName: formattedTitle,
        },
      })
    }

    // Looking for files
    watch(getUploadedFile, (data) => {
      isFileUploaded.value = Object.keys(data).length > 0
      stateFile.value = data
    })

    watch(stateFile, (data) => {
      isFileUploaded.value = Object.keys(data).length > 0
      isFileDataExist.value = data?.data?.byteLength ? true : false
    })

    const setFileUploaded = (uploadedFile) => {
      if (!uploadedFile) {
        fileUploadedUrl.value = ''
        fileUploadedPdf.value = ''
        return
      }

      if (uploadedFile) {
        const { data, name } = uploadedFile
        let file = uploadedFile

        const zip = new JSZip()
        zip.file(`${name}`, data)
        zip.generateAsync({ type: 'arraybuffer' }).then((content) => {
          file.data = content
          stateFile.value = file
        })
      }

      if (
        uploadedFile.type === 'image/png' ||
        uploadedFile.type === 'image/jpg' ||
        uploadedFile.type === 'image/jpeg'
      ) {
        fileUploadedUrl.value = uploadedFile.data
      } else if (uploadedFile.type === 'application/pdf') {
        fileUploadedPdf.value = uploadedFile.data
      }
      openConfirm.value = true
      clearUpload.value = false
      showOptions.value = true
    }

    const goToPendingActions = () => {
      router.push({ name: 'PendingActions' })
    }

    const closeConfirm = () => {
      openConfirm.value = false
    }

    const uploadAgain = () => {
      clearUpload.value = true
      fileUploadedUrl.value = ''
      fileUploadedPdf.value = ''
      dashboardStore.setClearFile(props.documentId)
      openConfirm.value = false
    }

    const saveFile = async () => {
      isLoading.value = true
      const formData = new FormData()

      const downloadFile = (file, saveFile) => {
        JSZip.loadAsync(file.data).then((zip) => {
          zip.files[file.name].async('blob').then((fileData) => {
            saveFile(fileData)
          })
        })
      }

      const sendFile = async (file) => {
        const fileSize = stateFile.value.size
        const fileType = stateFile.value.type

        const regeneratedFile = new File([file], stateFile.value.name, {
          size: fileSize,
          type: fileType,
        })

        formData.append('file', regeneratedFile)

        await dashboardStore.saveFile({
          docType: props.documentId,
          country: corpProfile.registrationAddress.country,
          companyType: corpProfile.companyDetails.companyTypeAcronym,
          formData,
          file: stateFile.value,
          goToPendingActions: true,
        })

        analyticsStore.track({
          event: 'Document Upload Saved',
          traits: {
            documentName: formattedTitle,
            documentFileType: stateFile.value.type.split('/')[1],
          },
        })

        isLoading.value = false
      }

      downloadFile(stateFile.value, sendFile)
    }

    const mq = reactive(useMediaQuery())

    return {
      formattedTitle,
      acceptedFileTypes,
      goToPendingActions,
      fileUploadedUrl,
      fileUploadedPdf,
      openConfirm,
      closeConfirm,
      uploadAgain,
      saveFile,
      setFileUploaded,
      isLoading,
      isFileUploaded,
      showOptions,
      clearUpload,
      getUploadedFile,
      mq,
      stateFile,
      isFileDataExist,
      country,
    }
  },
}
</script>

<style lang="postcss" scoped>
.documentary-evidence {
  &-modal {
    /deep/ .overlay-content {
      height: 100%;
    }

    /deep/ .card {
      @screen sm {
        @apply mt-12;
      }
    }
  }

  &-mobile-submit-button {
    /deep/ .button-inner {
      @apply px-2 !important;
    }
  }

  &-loader {
    @apply flex justify-center items-center text-orange-text;
    min-width: calc(100vw - 32px);
    height: calc(100vw + 106px);

    @screen sm {
      min-width: inherit;
      @apply w-80 h-96;
    }
  }
}

.warning {
  background-color: #f3f4f6;
  color: #5c667b;
}

.card-footer {
  z-index: 100;
}

.card-header {
  @apply h-14 !important;
}

ul {
  @apply type-caption mx-3;
}

li {
  @apply my-3;
  list-style-type: disc;
  font-size: 14px;
  line-height: 20px;
}
</style>
